import store from '@/store'
import router from '../router'
import axios from '../util/axios'

/**
 * 뷰 시작 시 
 */
export async function vueInit(){
    console.log('vue init')
    const vueInit = await axios.post('/vueinit')
    return vueInit.result
}

/**
 * 얼럿
 */
export function alert(messages='', type='info'){
    store.state.app.alertConfig.isShow = true
    if(['info', 'success', 'warning', 'error'].indexOf(type) !== -1){
        store.state.app.alertConfig.type = type
        store.state.app.alertConfig.messages = messages
    }else{
        store.state.app.alertConfig.type = 'error'
        store.state.app.alertConfig.messages = 'type은 info, success, warning, error만 가능합니다'
    }
}

/**
 * 페이지 이동 
 */
export function movePage(pageName, params = {}, history = true){
    if(router.history.current.name != pageName){
        history ? router.push({ name : pageName, params: params }) : router.replace({ name: pageName, params: params})
    }
}

/**
 * 숫자 제외 문자 삭제
 */
export function RemoveNotNumber(str){
    return str.replace(/\D/g, '');
}

/**
 * 숫자 형식으로 변환
 */
export function NumberFormat(num, failValue){
    if(parseFloat(num) != num){
        if(failValue === undefined)
            failValue = 0;
        return failValue;
    }
    return Number(num).toLocaleString();
}

/**
 * unit to 날짜
 */
export function unixToDatetime(unixTime){
    var date = new Date(unixTime)
    var year = date.getFullYear()
    var month = "0" + (date.getMonth()+1)
    var day = "0" + date.getDate()
    var hour = "0" + date.getHours()
    var minute = "0" + date.getMinutes()
    var second = "0" + date.getSeconds()
    return year + "-" + month.substr(-2) + "-" + day.substr(-2) + " " + hour.substr(-2) + ":" + minute.substr(-2) + ":" + second.substr(-2)
}

/**
 * ISO to 날짜
 */
export function ISOtoDateFormat(date) {
    const baseDate = new Date(date)
    const year = baseDate.getFullYear()
    const month = (baseDate.getMonth()+1).toString().padStart(2, '0')
    const day = (baseDate.getDate()).toString().padStart(2, '0')

    return `${year}-${month}-${day}`
}

/**
 * 가격 형식(3자리 마다 쉼표)
 */
export function priceFormat(val){
    val = typeof val == "string" ? val : val.toString()
    return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * constant code to name
 */
export function codeToName(object, code){
    const lang = store.get('app/lang') || 'ko'
    if(object && code){
        return  object[Object.keys(object).find(key => object[key].code == code)].name[lang]
    }
}

export function isIosApp() { 
    return typeof window.webkit !== 'undefined'
		&& typeof window.webkit.messageHandlers !== 'undefined'
		&& typeof window.webkit.messageHandlers.RequestLoginInfo !== 'undefined'
}

export function isAndroidApp() { 
    const userAgent = navigator.userAgent.toLowerCase()
    return userAgent.indexOf('android') !== -1 && window.android !== undefined
}

export function isMobile() { 
    const filter = "win16|win32|win64|mac";
    if (navigator.platform) {
        return 0 > filter.indexOf(navigator.platform.toLowerCase())
    }
}

export function isWebView() {
    const userAgent = navigator.userAgent
    return userAgent.indexOf('KEV_IOS') !== -1 || userAgent.indexOf('LEV_ANDROID') !== -1 || userAgent.indexOf('e-charger_ANDROID') !== -1 || userAgent.indexOf('e-charger_IOS') > -1
}

export function NaviStart(  name, lat, lng, key) {
    if (store.state.app.naviInfo > 0) {
		return;
    }
    store.state.app.naviInfo = { name: name, x: parseFloat(lng), y: parseFloat(lat), coordType: 'wgs84' };
    if (store.state.app.loadStateNaviScript > -1) {
        window.Kakao.Navi.start(store.state.app.naviInfo);
    } else {
		store.state.app.loadStateNaviScript = 0;
		var script = document.createElement('script');
		script.type = 'text/javascript';
        script.onload = function () {
			store.state.app.loadStateNaviScript = 1;
            window.Kakao.init(key);
			window.Kakao.Navi.start(store.state.app.naviInfo);
			store.state.app.naviInfo = null;
		};
        script.onerror = function () {
			store.state.app.loadStateNaviScript = -1;
			store.state.app.naviInfo = null;
			this.alert('내비게이션을 실행시키는데 실패했습니다.', 'error') 
		}
		script.src = "https://developers.kakao.com/sdk/js/kakao.min.js";
        document.head.appendChild(script);
	}
}