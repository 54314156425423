import axios from '../axios'
/**
 * REQUEST FORM
 * {controllerName}/{function}
 */

export async function regiBroken(sendData) {
  const res = await axios.post('/brokenReport/regiBroken', sendData)
  return res
}

export async function regiBrokenWithFormData(formData) {
  const res = await axios.post('/brokenReport/regiBroken', formData, {
      headers: {
          'Content-Type': 'multipart/form-data',
      }
  })
  return res
}

export async function getBrokenList(page, limit) {
    const res = await axios.post('brokenReport/getBrokenList', {page : page, limit : limit})
    return res
}